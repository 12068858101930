
.search {

    

    .cards-container {
        display: grid;
        grid-gap: 24px;
        grid-template-columns: repeat(3,1fr);
        @include brandcards;
    }

    .loading-center {
        top: calc(50% - 2.5rem);
        left: 0;
        position: absolute;
    }
    
}

@media screen and (max-width: 768px) {

    
    .search {
        align-self: flex-start;
        
        .view-web { display: none; }
        
        .cards-container {
            display: flex;
            width: 90%;
            min-height: 600px;
            overflow-y: scroll;
            @include brandcards;
        }
    }

}