.taps-column {
    width: 50%;
}

.tap {
    height: 100%;
    padding: 12px 0;
    font-size: $fontText;
    background-color: $pink;
    font-family: $OpenSans;
}

.tap-active {
    border-bottom: 5px solid $purple;
}

@media screen and (max-width: 768px) {
    .search-tap {
        background-color: $pink;
    }
    .taps-column {
            width: 50%;
            padding: 12px 0;
        }
    
        .tap {
            padding: 0;
            font-size: $fontText;
            background-color: $pink;
        }
    
        .tap-active {
            border-bottom: 4px solid $purple;
        }
}