.footer{
    background: black;
    padding: 16px;

    .footer-elemts h5{
        cursor: pointer;
        -webkit-transition: color .5s;
         transition: color .5s;
        &:hover{
            color: $pink;
            
        }
    }
}