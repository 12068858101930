/**
*   colors.scss
*   @description: Variables de colores
*/


$black:             #000000;
$blackOverlay:      rgba(0, 0, 0, 0.6);
$white:             #FFFFFF;
$pink:              #FF0357;
$purple:            #7633fd;
$lighPurple:        #7633fda2;
$red:               #CF000F;
$gray:              #b3b3b3;
$darkGray:          #8f8f8f;
$lightGray:         #f1f1f1;

/* Classes de colores */

.color-white {
    color: $white;
}

.color-black {
    color: $black;
}

.color-pink {
    color: $pink;
}

.color-purple {
    color: $purple;
}

.color-gray {
    color: $gray;
}

.color-darkgray {
    color: $darkGray;
}

.color-red {
    color: $red;
}