/**
*   register.scss
*   @description: Estilos de la página de registro
*/

.register, #modal-register {
    
    .input-container {
        background-color: #EFEFEF;
        border: 1px solid transparent;
        transition: border 400ms ease-in-out;
        i {  
            min-width: 12%;
            color: #777777;
            padding-left: $paddingMedium;
        }
        .input { 
            min-width: 88%; 
            padding: $paddingNormal $paddingMedium;
        }
        .input-select { appearance: none; }
        &:focus-within { border: 1px solid $pink; }
    }

    .forms { width: 45%; }    

    .btn {
        width: 100%;
        padding: $paddingNormal $paddingMedium;
    }
   
    #btn-marcas { 
        width: 100%; 
        padding: $paddingNormal $paddingMedium;
    }
}

.register .view-web {
    @include backgroundImage;

    .overlay {
        background-color: rgba(0, 0, 0, 0.205);
        height: 100vh; 
    }

    .container-card {
        width: 100%;
        
        .content { position: relative; }
        .card-splash-influencer {
            width: 70%;
            cursor: pointer;
            padding: 45px;
            background-color: rgba($pink, 0.8);
        }

        .card-splash-marca {
            width: 70%;
            cursor: pointer;
            padding: 45px;
            background-color: rgba($purple, 0.8);
        } 

        
        .overlay-card {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            transition: .5s ease;
            background-color: $pink;
        }

        .overlay-card2 {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            transition: .5s ease;
            background-color: $purple;
        }
    
        .content:hover .overlay-card{
            opacity: 1;
        }

        .content:hover .overlay-card2{ opacity: 1; }
  
        .text-cards {
            color: white;
            font-size: 20px;
            position: relative;
            top: 40%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            text-align: center;
        }

        .text-tittle-card { font-size: 25px; }
      
    }

    .more { 
        bottom: 0;
        position: absolute;
        padding: $paddingMedium;
        background: linear-gradient(to right, $pink, $purple);
    }
}


@media screen and (max-width: 768px) {

    .register {
        .forms {
            width: 90%;
        }
        .view-web {
            display: none;
            overflow: hidden;
        }

        .modal-register-brand {
            display: none;
        }
        
    }    

    .login, #modal-register {
        h2 { font-size: $fontMedium; }
        #h2-register-brand { font-size: $fontRegular; }
        .input-container {
            i { 
                min-width: 12%;
                padding-left: $paddingMedium;
            }
            .input { 
                min-width: 88%;
                padding: $paddingNormal $paddingMedium;
            }
            &:hover { border: 1px solid $pink; }
        }
        
        .btn {
            width: 100%;
            padding: $paddingMedium;
        }
           
    }

}