@media screen and (min-width: 1440px) {

    .splash {


    
    }
}

.splash {

    @include backgroundImage;

    .icon-transparent { color: transparent; }

    .overlay {
        background-color: rgba(0, 0, 0, 0.205);
        height: 100vh; 
    }

    .overlay-desk {
        background-position: center;
        background-size: cover;
        background-color: rgba(0, 0, 0, 0.205);
        height: 100vh; 
    }
        
    .container-card {
        width: 100%;
            .content { position: relative; }
            .card-splash-influencer {
                width: 65%;
                height: 95%;
                cursor: pointer;
                padding: 45px;
                margin-right: 8px;
                background-color: rgba($pink, 0.7);
                border-radius: 4px;
            }

            .card-splash-marca {
                width: 65%;
                height: 95%;
                cursor: pointer;
                padding: 45px;
                margin-left: 8px;
                background-color: rgba($purple, 0.7);
                border-radius: 4px;
            } 
            
            .overlay-card {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: 100%;
                width: 100%;
                opacity: 0;
                transition: .5s ease;
                background-color: $pink;
            }

            .overlay-card2 {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: 100%;
                width: 100%;
                opacity: 0;
                transition: .5s ease;
                background-color: $purple;
            }
  
        .content:hover .overlay-card { opacity: 1; }

        .content:hover .overlay-card2 { opacity: 1; }
  
        .text-cards {
            color: white;
            font-size: 20px;
            position: relative;
            top: 40%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            text-align: center;
        }

        .text-tittle-card { font-size: 25px; }
    }

    .more { 
        bottom: 0;
        position: absolute;
        padding: $paddingMedium;
        background: linear-gradient(to right, $pink, $purple);
    }

    .information {
        background-color: $white;
        .left {
            width: 60%;
            align-self: flex-start;
        }

        .left-invert {
            width: 40%;
        }

        h2 {
            font-size: 1.37rem;
        }

        .buttons {
            width: 100%;
            .btn-container-buttons {
                justify-content: center;
                &:first-child { margin-right: 32px; }
                &:last-child { margin-left: 32px; }
            }
            button {
                width: 80%;
            }
            .button-information {
                font-size: 1.2rem;
                font-family: $OpenSans;
                padding: $paddingMedium;
                font-weight: $weightBold;
                background-color: $white;
            }
            .active-influencer {
                border: 5px solid $pink;
                transition: all 350ms ease-in-out;
                &:hover {
                    color: $white;
                    background-color: $pink;
                    border: 5px solid $white;
                }
            }
            .active-brand {
                border: 5px solid $purple;
                transition: all 350ms ease-in-out;
                &:hover {
                    color: $white;
                    background-color: $purple;
                    border: 5px solid $white;
                }
            }
            .inactive-brand {
                border: 2px solid $gray;
                transition: all 350ms ease-in-out;
                &:hover {
                    color: $white;
                    background-color: $gray;
                    border: 5px solid $white;
                }
            }
            .inactive-influencer {
                border: 2px solid $gray;
                transition: all 350ms ease-in-out;
                &:hover {
                    color: $white;
                    background-color: $gray;
                    border: 5px solid $white;
                }
            }
        }

        

        .info-cards {
            width: 50%;
                &:first-child { margin-right: 32px; }
                &:last-child { margin-left: 32px; }
            h3 {
                font-size: 1.2rem;
            }
            p {
                width: 80%;
            }
        }        

        .right { width: 40%; }

        .right-invert {
            width: 60%;
        }

        .screen-influencer, .screen-brand {
            img { max-height: 480px; }
        }

    }

    .footer {
        bottom: 0;
        height: 50px;
        padding: $paddingMedium;
        background: $purple;

        .social-media {
            width: 10%;
        }

        .legal {
            width: 40%;
        }

        .logo {
            .logo-footer{
                height: 55px;
            }
        }
    }

}

.activate {
    .logo img { max-height: 200px; }

    .text img { max-height: 100px; }

    .btn {
        width: 280px;
        padding: $paddingMedium $paddingLarge;
    }
}

@media screen and (max-width: 992px) {

    .splash {

        @include backgroundImage;
        height: 100vh;
    
        .overlay {
            background-color: rgba(0, 0, 0, 0.205);
            height: 100vh; 
        }
    
        .container-card {
            width: 85%;
            
            .content{
                position: relative;
            }
            .card-splash-influencer{
                padding: 30px 60px;
                background-color: $pink;
                width: 50%;
                cursor: pointer;
            }
    
            .card-splash-marca {
                padding: 30px 60px;
                background-color: $purple;
                width: 50%;
                cursor: pointer;
            } 
    
            
            .overlay-card {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: 100%;
                width: 100%;
                opacity: 0;
                transition: .5s ease;
                background-color: $pink;
            }
    
            .overlay-card2 {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: 100%;
                width: 100%;
                opacity: 0;
                transition: .5s ease;
                background-color: $purple;
            }
      
        .content:hover .overlay-card { opacity: 1; }
        
        .content:hover .overlay-card2 { opacity: 1; }
        
        .text-cards {
            color: white;
            font-size: 16px;
            position: relative;
            top: 40%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            text-align: center;
        }

        .text-tittle-card { font-size: 20px; }      
          
        }
    
    }

}

@media screen and (max-width: 768px) {

    .splash {
        min-height: 100vh;

        .view-web { display: none; }

        .slider .content { width: 80%; }

        .overlay { background-color: rgba(0, 0, 0, 0.205); }
    
        .logo img { max-height: 80px; }

        .text img { max-height: 50px; }
    
        .flickity-prev-next-button { display: none; }
    
        .flickity-page-dots .dot {
            width: 8px;
            height: 8px;
            opacity: 1;
            background: transparent;
            border: 2px solid white;
        }
            
        .flickity-page-dots .dot.is-selected { background: white; }
    
        .btn {
            font-weight: $weightSemi;
            padding: $paddingMedium;
            width: 80%; 
        }
    }

}