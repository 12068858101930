
.profile-settings {
    #overlay-settings {
        .logo img { max-height: 100px; }
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-color: rgba($black, 0.8);
        .waala img { max-height: 50px; }
        .btn { padding: $paddingMedium $paddingLarge; }
    } 
}

@media screen and (max-width: 768px) {
    
    .profile-settings { min-height: 100vh; }

    .profile {
        background-color: $lightGray;

        .movile-labels {
            height: 65px;
            background-color: #FF0357;
            border-radius: 4px;

            p {
                font-size: .8rem;
                line-height: 17px;
            }
        }
        
        .view-web { display: none; }
        
        .profile-container {
            position: relative;
            background:  linear-gradient(to bottom, $pink 0%, $pink 50%, $lightGray 50%, $lightGray 100%);
            padding-top: 15px;
    
            .image-profile {
                width: 120px;
                height: 120px;
                border-radius: 50%;
                margin: 10px 0px 10px 0px;
                border: 1px solid $black;
                object-fit: cover;
                object-position: center center;
            }
    
            h5 { font-size:  $fontMini; }
        
            .i-left{
                position: absolute;
                left: 5%;
                font-size: $fontMedium;
            }
        }
    
        .basic-data {
            background: $white;
            border: 1px solid $gray;
            border-radius: 6px;
            padding: 5px;
            .icon {
                min-width: 10%;
                max-width: 10%;
            }
            .input-profile, .input-profile-noline {
                width: 85%;
                margin-left: 10px;
            }
            #city {
                appearance: none;
                -moz-appearance : none;
            }
        } 

        .i-right {
            position: absolute;
            right: 5%;
            top: 16px;
            font-size: $fontRegular;
        }

        #connect-instagram {
            padding: $paddingMedium;
        }               
    }
    
}