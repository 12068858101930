.favorites {

    background-color: $lightGray;

    .container-favorites {
        display: grid;
        grid-gap: 24px;
        grid-template-columns: repeat(3,1fr);
        .loading-center {
            top: calc(50% - 2.5rem);
            left: 0;
            position: absolute;
        }
        @include brandcards;
    }

    .chat-column { width: 75%; }
    
    .influencer-list {
        background-color: $white;
        padding: $paddingNormal;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);

        .image { 
            width: auto; 
            margin-right: $paddingNormal; 
        }
        .image-cover {
            width: 80px;
            height: 80px;
            object-fit: cover;
            border-radius: 50%;
            margin: $marginNormal;
            margin-left: 0;
            object-position: center center;
        }
    }    

}

@media screen and (max-width: 768px) {

    .favorites {
        min-height: 100vh;
        .view-web { display: none; }

        .container-favorites {
            display: flex;
            width: 92%;
            @include brandcards;
        }

        .influencer, .brands {

            min-height: 90px;
            border-bottom: 1px solid $gray;

            h4 { 
                font-size: $fontText; 
                max-width: 90%;
            }            

            .image { width: 30%; }

            .image-cover {
                width: 60px;
                height: 60px;
                object-fit: cover;
                border-radius: 50%;
                margin: $marginNormal;
                margin-left: 0;
                object-position: center center;
            }
            
        }
        .chat-column { width: 100%; }
        
        .influencer-list {
            background-color: $white;
            padding: 0 $paddingNormal;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
        }

    }

}