/**
*   chats.scss
*   @description: Estilos de la página de chats
*/

.chats {
    background-color: $lightGray;
    width: 100%;

    .chat-search {
        max-width: 100%;
        margin-bottom: $marginMedium;
        i {
            color: $darkGray;
            position: absolute;
            font-size: 0.9rem; 
            margin-left: $marginNormal;
            padding-left: $paddingMedium;
        }
        input {
            width: 100%;
            padding-left: 36px;
            border-radius: 16px;
            margin-left: $marginNormal;
            background-color: $lightGray; 
        }
    }

    .chats-desk-list {
        width: 55%;
    }

    .center-chat {
        justify-content: center;
        width: 100%;
    }

    .full {
        width: 100%;
    }

    .pre-chat {
        background-color: $white;
    }
    .chats-container {
        height: 600px;
        
        overflow-y: scroll;
        background-color: $white;

        .icon-delete {
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: $fontRegular;
            color: $pink;

        }

        .icon-search-chats{
            position: relative;
            left: 9%;
            color: gray;
        }

        .chat-list {
            min-height: 90px;
            position: relative;
            cursor: pointer;

            h4 { font-size: 1em; }

            h5 { font-size: .8em; }

            .chat-column { width: 35%; }

            .image-cover {
                width: 70px;
                height: 70px;
                border-radius: 50%;
                margin: 10px;
                object-fit: cover;
                object-position: center center;
            }
            .chat-details {
                padding-right: 10px;
            }         
        }

        .selected-chat {
            h4 { font-size: 1em; color: $white; }
            .chat-image, .chat-details {
                background-color: rgba($pink, 0.2);
            }
        }

    }
    
}

@media screen and (max-width: 992px) {

    .chats {
        .chats-container {
            width: 95%;
        }
        
        .chats-desk-list {
            width: 100%;
        }
    }

}

@media screen and (max-width: 768px) {

.chats {

    width: 100%;
    background-color: $lightGray;

    .chat-search {
        border-radius: 16px;
        background-color: $white;
        i { 
            color: $darkGray;
            font-size: 0.9rem; 
            padding-left: $paddingMedium;
        }
        input { 
            border-radius: 16px;
            background-color: white; 
        }
    }

    .view-web { display: none; }    
    
    .chats-container {
        width: 100%;
        height: auto;
        padding: 0px;
        margin-bottom: 64px;
        overflow-y: scroll;
        background-color: $lightGray;

        .icon-delete {
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: $fontRegular;
            color: $pink;

        }
     
        .chat-list {
            position: relative;
            min-height: 90px;
            background-color: $white;
            padding: 0 $paddingNormal;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);

            h4 { font-size: 1em; }

            h5 { font-size: .8em; }

            .chat-column { width: 35%; }
           
            .image-cover {
                width: 70px;
                height: 70px;
                border-radius: 50%;
                margin: 10px 10px 10px 0px;
                object-fit: cover;
                object-position: center center;
            }
        
        }
    }
    
}

}