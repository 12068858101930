/**
*   header.scss
*   @description: Estilos para los Headers
*/

@media screen and (min-width: 1440px) {

	.header-logged {
		
		.main-container {
			margin-left: 100px;
		}

	}

}

.header-profile {
	width: 70%;
	border-radius: 8px;
}

.header-single {
	background-color: $pink;
	position: relative;
	padding: $paddingNormal 0;
	.logo img { height: 50px; }
	.back {
		z-index: 1;
		margin-left: 5px;
		top: 24px;
		position: absolute;
	}
}

/* Headers WEB */

.header-web-login {
	background-color: #000000bd;
	padding: 10px 0;
	z-index: 1;
    position: fixed;
	top: 0;
	
	.btn-splash {
		padding-left: $paddingNormal;
	}

	.logo {
        height: 55px;
	}

	#w-logo {
		position: absolute;
		left: calc(50% - 24px);
	}
	
	.letter {
		height: 34px;
		
	}

    .icon-search-web {
        position: absolute;
        left: 3%;
        color: #b3b3b3;
	}	
}

.header-web-login2 {
	background-color: $pink;
	padding: 10px 0;
	z-index: 1;
    position: relative;
	top: 0;
	
	.btn-splash {
		padding-left: $paddingNormal;
	}

	.logo{
        height: 55px;
    }
	
    .icon-search-web {
        position: absolute;
        left: 3%;
        color: #b3b3b3;
    } 
}

.header-web-interest {
	background-color: $pink;
	padding: 10px 0;
	z-index: 1;
    position: relative;
	top: 0;
	
	.back-icon{
		padding-right: $paddingLarge;
	}
	
}

.header-logged {
	background-color: $pink;
	padding: 10px 0;
	z-index: 1;
    position: relative;
	top: 0;

	.column-logo-header {
		width: 50%;
	}

	.column-both {
		width: 50%;
	}

	.column-search-header {
		width: auto;
	}

	.column-button-header {
		width: auto;
		margin-left: $marginNormal;
	}

	.btn-search-purple {
		height: 38px;
		font-family: $OpenSans;
		background-color: $white;
		border: 1px solid $purple;
	}

	.logo { 
		height: 35px; 

		a {
			position: absolute;
			left: 23px;
		}
	
	}
	.icon-add { padding-right: $paddingNormal; }
	.icon-search-web {
        position: absolute;
        left: 3%;
        color: #b3b3b3;
    }
	
}

.header-search {
	form { position: relative; }

	.icon-search {
		left: 18%;
		top: 12px;
		color: $gray;
		position: absolute;
	}

	.icon-search-search {
		top: 12px;
		font-size: 13px;
		left: 20%;
	}

   .burger-wrapper { cursor: pointer; }
  
  	#menu-filter {
		background: $white;
    	width: 25%;
    	height: 100vh;
    	position: fixed;
    	z-index: 2;
    	right: 0;
    	transition-timing-function: cubic-bezier(10, 2, 3, 1);
    	box-shadow: 6px -2px 20px 0px grey;
    	transform: translateX(50rem);
    	top: 0;
    	transition: 0.5s;

    	.input-container {
        	padding: 10px 0px 10px 0px;
        	h4 {
            	font-size: .93rem;
        	}
    	}

    	.header-filter {
    		background-color: $pink;
			padding: 22px;
		}
		
		#followers {
			width: 100%;
		}
	}
  
  	#menyAvPaa {
		display: none;
		&:checked ~ #menu-filter {
			transform: translateX(0rem);
		}
	}

	.filter-icon {
		position: absolute;
		top: 21px;
		right: 17px;
	}

	#filter-text {
		margin-left: 2px;
	}
  
  	#filter {
		position: relative;
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		flex-direction: row;
	}
  
	#closemain{
		font-size: 25px;
	}
  
	#menyAvPaa:checked ~ #filter  {
		color: pink;
	}
}

/* --- HeaderChat ---	*/

.header-chat {
	height: 64px;
	margin-bottom: 8px;
	background-color: $white;
	padding: $paddingNormal 0;
	border-bottom: 1px solid #d1d1d1;
	
	.header-profile { width: 100%; }

	.search-chat-icon {
		color: $purple;
		margin: 8px;
	}
	
	.image {
		margin: 0px $paddingMedium 0px 0px;
		img {
			width: 32px;
			height: 32px;
			border-radius: 50%;
		}
	}

	.btn{
		border-radius: 4px;
		font-size: $fontText;
	}

	.text-header {
		color: $black;
	}

	.back {
		display: none;
	}

	.space-icon {
		margin-right: 8px;
	}
}

@media screen and (max-width: 768px) {

	.header-single {
		background-color: $pink;
		padding: 0;
		height: 68px;
		position: sticky;
		z-index: 1;
		.logo img { height: 32px; }
	} 

	.sticky-header {
		top: 0;
		z-index: 1;
		position: sticky;
	}

	.search-icon-center {
		padding: $paddingNormal;
	}

	.search-sticky {
		position: sticky;
		top: 0;
		z-index: 1;
	
	}

	.header-search {
	
		form { position: relative; }
		.icon-search {
			left: 18%;
			top: 12px;
			color: $gray;
			position: absolute;
		}	
		
		.icon-favorites {
			z-index: 1;
			margin-left: 5px;
			top: 8px;
			right: 8px;
			position: absolute;
		}
		.no-flex {
			display: block;
		}		

		.no-flex * {
			display: block;
			font-family: $OpenSans;
		}

		.no-flex svg {
			display: inline-block;
		}

		.no-fles .css-1hwfws3 {
			width: auto;
		}

		.btn-purple-aply {
			border-radius: 4px;
			padding: $paddingNormal;
			background-color: $purple;
		}

		button {
			position: relative;
			border-radius: 4px;
			padding: $paddingNormal;
			padding-left: 24px;
			background-color: $white;
			font-weight: $weightSemi;
			i {
				left: 0;
				top: 14px;
				padding-left: 6px;
				position: absolute;
			}
		}				

		.p-influencers {
			width: 135px;
		}
	
		.icon-search-search {
			top: 12px;
			font-size: 13px;
			left: 20%;
		}
	
	   .burger-wrapper { cursor: pointer; }
	  
		  #menu-filter {
			background: $white;
			width: 80%;
			height: 100vh;
			position: fixed;
			z-index: 2;
			right: 0;
			transition-timing-function: cubic-bezier(10, 2, 3, 1);
			box-shadow: 6px -2px 20px 0px grey;
			transform: translateX(50rem);
			top: 0;
			transition: 0.5s;
	
			.input-container {
				padding: 10px 0px 10px 0px;
				h4 {
					font-size: .93rem;
				}
			}
	
			.header-filter {
				background-color: $pink;
				padding: 16px;
			}
			
			#followers {
				width: 100%;
			}
		}
	  
		  #menyAvPaa {
			display: none;
			&:checked ~ #menu-filter {
				transform: translateX(0rem);
			}
		}
	
		.filter-icon {
			position: absolute;
			top: 21px;
			right: 17px;
		}
	
		#filter-text {
			font-size: 0.7rem;
		}
	  
		  #filter {
			position: relative;
			cursor: pointer;
			display: flex;
			justify-content: space-between;
			flex-direction: column;
		}
	  
		#closemain{
			font-size: 25px;
		}
	  
		#menyAvPaa:checked ~ #filter  {
			color: white;
		}
	}
	
	.header-chat {
		background-color: $pink;
		padding: $paddingNormal 0;
		height: 64px;
		.image {
			margin: 0 $paddingMedium;
			img {
				width: 36px;
				height: 36px;
				border-radius: 50%;
			}
		}

		.btn-purple { background-color: $purple; }

		
		.search-chat-icon {
			color: $white;
			margin-right: 0;
		}

		.description { 
			width: 55%;
			h5 { font-size: 0.71rem; font-weight: $weightBold; margin-top: 6px; }
			p { font-size: 0.65rem; }
		}

		.pay {
			width: 40%; 
			.btn {
				width: 100%;
				font-size: 0.7rem;
			}
		}

		.search { 
			height: 100%; 
			padding-bottom: 4px;
			padding-left: $paddingNormal;
		}
		
		.text-header {
			color: $white;
		}

		.btn{
			cursor: pointer;
			border-radius: 50px;
			font-family: "Open Sans", sans-serif;
			padding: 8px 16px;
			font-size: $fontTiny;
		}

		.back {
			display: block;
		}
	
		.space-icon {
			margin-right: 0px;
			top: 11px;
			left: 6px;
		}
	}

	.header-bloqued {
		.back {
			top: 21px;
			left: 16px;
			position: absolute;
		}
	}

}
