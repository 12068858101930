
.suscription {
    min-height: inherit;
}

.payment-list {

    border-bottom: 1px solid $gray;
    margin-bottom: $paddingMedium;
    &:last-child { border-bottom: 0; }
    .image-column { width: 40%; }

    .details-list { 
        width: 60%;
        padding: $paddingNormal $paddingMedium;
    }

    img { width: 100%; height: 100px; }
}


@media screen and (max-width: 768px) {

    .suscription {
        min-height: 100vh;
        .table-responsive { box-shadow: 0 1px 2px 0 rgba(0,0,0,0.10); }
        table {
            font-family: $OpenSans;
            th {
                color: $white;
                background-color: $pink; 
            }
            tr:nth-child(even) { background-color: $white; }
            td { padding: $paddingNormal; }
        }
    }

    .payment-list {

        border-bottom: 1px solid $gray;

        .image-column { width: 40%; }

        .details-list { 
            width: 60%; 
            padding: $paddingNormal $paddingMedium;
        }

        img { width: 100%; height: 100px; }
    }

}