.chat {
    background-color: $lightGray;
    height: 100vh;

    .main-chat-column {
        background-color: $lightGray;
    }

    .content-chat-list {
        width: 55%
    }
     
    .container-desktop { width: 98%; }

    .container-controls {
        width: 100%;
        padding-left: 16px;
    }

    .content-chat {
        width: 98%;
        height: 465px;
    }

    .chat-column {
        background-color: white;
    }

    .chat-content {
        height: 600px;
        border-left: 1px solid #d1d1d1;
    }

    #chat_messages {

        
        align-self: flex-start;

        .message {
            .p-img {
                height: 100%;
                a {
                    height: 100%;
                }
            }
        } 
        
        
        .message-user {
            p {
                max-width: 90%;
                border-radius: 8px;
                padding: $paddingMedium $paddingNormal;
                background-color: rgba(#bbbbbb, 0.4);
                margin: 4px 0;
            }
        }
    
        .message-me {
            p {
                max-width: 90%;
                border-radius: 8px;
                padding: $paddingMedium $paddingNormal;
                background-color: rgba(#ff679a, 0.4);
            }
        }
    
        .message-alert {
            p {
                width: 98%;
                color: $black;
                text-align: center;
                font-weight: $weightSemi;
                justify-content: center !important;
                padding: $paddingMedium $paddingNormal;
                background-color: rgba(245, 215, 110, 1);
            }
        }
        
    }    

    .icon-sent-image{
        font-size: $fontMedium;
    }

    .icon-sent{
        font-size: $fontHuge;
        margin-left: $marginNormal;
    }

    .bottom-controls {
        bottom: 0;
        z-index: 1;
        padding: 12px 0;
        position: relative;
        background-color: $white;
        box-shadow: 0px -0.1px 5px 0px rgba(217,217,217,1);
        .left { 
            width: 90%;
            input { width: 96%; }
        }
        
        .btn-send {
            background-color: transparent;
            padding: 0 $paddingNormal;
        }
    }

    #chat-search {
        padding: 10px 16px;
        background-color: $white;
        box-shadow: 0px -0.1px 5px 0px rgba(217,217,217,1);
        .input {
            width: 95%;
        }

        .container-controls {
            width: 100%;
            padding-left: 0px;
        }
    }

    }

@media screen and (max-width: 992px) {
    .chat {
        
        .chats-container {
           width:  100%;
        }
        .content-chat-list {
            width: 100%
        }
    }
}

@media screen and (max-width: 768px) {

    .chat {
        background-color: $lightGray;

        #chat-search {
            padding: 10px 16px;
            background-color: $white;
            box-shadow: 0px -0.1px 5px 0px rgba(217,217,217,1);
           
                .input {
                    width: auto
                }

                .container-controls {
                    width: 100%;
                }
            }
        

        .content-chat {
            min-height: 500px;
            width: 90%;
        }

        .container-desktop {
            width: 90%;
        }

        .view-web { display: none}
        .message-user {
            p {
                max-width: 90%;
                border-radius: 8px;
                margin: 2px 0;
                padding: $paddingMedium $paddingNormal;
                background-color: rgba(#bbbbbb, 0.4);
            }
        }

        .message-me {
            p {
                max-width: 90%;
                border-radius: 8px;
                margin: 2px 0;
                padding: $paddingMedium $paddingNormal;
                background-color: rgba(#ff679a, 0.4);
            }
        }

        .bottom-controls {
            bottom: 0;
            z-index: 1;
            padding: 12px 0;
            position: fixed;
            background-color: $white;
            box-shadow: 0px -0.1px 5px 0px rgba(217,217,217,1);
            .left { 
                width: 90%;
                input { width: 96%; }
            }
            
            .btn-send {
                background-color: transparent;
                padding: 0 $paddingNormal;
            }
        }

    }
}