/**
*   brandcard.scss
*   @description: Estilos para las cards en Home > Influencers
*/

@mixin brandcards {
     
    .brand-cards, .influencer-cards, .favorites .brand-cards {
        min-height: 320px;
        width: 100%;
        margin: $paddingNormal 0;
        border-radius: 4px;
        .image {
            max-height: 280px;
            min-height: 280px;
            position: relative;
            border-radius: 4px 4px 0 0;
            background-color: $black;
            img { 
                width: 100%;
                opacity: 0.6;
                border-radius: 4px 4px 0 0;
            }
        }
        .title {
            top: 12px;
            position: absolute;
        }

        .data {
            bottom: 12px;
            position: absolute;
            .column { width: 50%; }
        }

        .btn { 
            width: 50%;
            border-left: 0;
            border-bottom: 1px solid #E7E7E7;
            border-right: 0;
        }
        
        .btn-container {
            border-radius: 0 0 4px 4px;
            background-color: $white;
            box-shadow: 0 3px 2px rgba(0, 0, 0, 0.16);
            .btn {
                background-color: $white;
                border-radius: 4px;
                padding: 10px $paddingMedium;
                &:first-child {
                    border-radius: 0 0 0 4px; 
                }
                &:last-child { 
                    border-radius: 0 0 4px 0;
                }
            }
        }
        .btn-owner { width: 80%; }
        #delete-publication, #editar-publication { width: 50% ; opacity: 0.6;}
        #btn-premium { 
            width: 100%;
            border: 0;
            background-color: $purple; 
        }
        .divider { border: 1px solid #E7E7E7; }
    }       

    .brand-cards {
        .title {
            bottom: 0px;
            position: absolute;
        }
    }

    
    
    @media screen and (max-width: 768px) {

        .brand-cards, .influencer-cards, .favorites .brand-cards {
            min-height: 320px;
            width: 100%;
            margin: $paddingNormal 0;
            border-radius: 4px;
            .image {
                max-height: 280px;
                min-height: 280px;
                position: relative;
                border-radius: 4px 4px 0 0;
                background-color: $black;
                img { 
                    width: 100%;
                    opacity: 0.6;
                    border-radius: 4px 4px 0 0;
                }
            }
            .title {
                top: 12px;
                position: absolute;
            }

            .data {
                bottom: 12px;
                position: absolute;
                .column { width: 50%; }
            }

            .btn { 
                width: 50%;
                border-left: 0;
                border-bottom: 1px solid #E7E7E7;
                border-right: 0;
            }
            
            .btn-container {
                border-radius: 0 0 4px 4px;
                background-color: $white;
                box-shadow: 0 3px 2px rgba(0, 0, 0, 0.16); 
                .btn {
                    border-radius: 4px;
                    padding: 10px $paddingMedium;
                    &:first-child {
                        border-radius: 0 0 0 4px; 
                    }
                    &:last-child { 
                        border-radius: 0 0 4px 0;
                    }
                }
            }
            .btn-owner { width: 80%; }
            #delete-publication, #editar-publication { width: 50% ; opacity: 0.6;}
            #btn-premium { 
                width: 100%;
                border: 0;
                background-color: $purple; 
            }
            .divider { border: 1px solid #E7E7E7; }
        }

        .publication-card .data {

            .column { width: 50%; }

            .name { font-size: 1rem; }
        }

        .brand-cards .name {
            font-size: 1rem;
        }

    }

}