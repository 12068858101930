/**
*   bottombar.scss
*   @description: Menu inferior fijo
*/

.bottom-bar {
    bottom: 0;
    z-index: 1;
    padding: $paddingNormal 0;
    padding-bottom: 2px;
    position: fixed;
    background-color: $white;
    box-shadow: 0px -0.1px 5px 0px rgba(217,217,217,1);

    p { font-size: 0.7rem; color: #828282; }
    
    .menu-item {
        width: 20%;
        color: #828282;
        i { font-size: $fontLarge; }

        .fav {
            padding-left: 10px
        }

        .chats {
            padding-right: 10px;
            background-color: transparent;
        }
    }

    #add-new { 
        bottom: 12px;
        left: 50%;
        margin-left: -1.25rem;
        font-size: 2.5rem; 
        position: absolute;
    }

    
    #chats {
        position: relative;
        #chat-circle {
            position: absolute;
            top: -4px;
            right: 0;
            font-size: 1rem;
            color: $purple;
        }
        p {
            margin-left: -12px;
        }
    }   
    
    .bottom-profile-icon {
        margin-left: 3px;
    }

}

.active-bottom {
    color: $pink;
    p { color: $pink; }
}