/**
*   register.scss
*   @description: Estilos de la página de registro
*/

.login {
    .input-container {
        background-color: #EFEFEF;
        border: 1px solid transparent;
        transition: border 400ms ease-in-out;
        i {  
            color: #777777;
            padding-left: $paddingMedium;
        }
        &:focus-within { border: 1px solid $pink; }
    }
    
    .btn {
        width: 100%;
        padding: $paddingMedium;
    }

    #btn-marcas { 
        width: 100%; 
        padding: $paddingNormal $paddingMedium;
    }

    #btn-instagram {
        border: 1px solid $pink;
        background-color: $white;
    }
}

@media screen and (max-width: 768px) {

    .register {
        h2 { font-size: $fontMedium; }
        #h2-register-brand { font-size: $fontRegular; }
        .input-container {
            i {
                min-width: 12%;
                padding-left: $paddingMedium;
            }
            .input { 
                min-width: 88%; 
                padding: $paddingNormal $paddingMedium;
            }
            &:hover { border: 1px solid $pink; }
        }
    }

}