
.blocked {

    .blocked-list {
        background-color: $white;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
        .blocked-data{
            padding: 8px;
        }
    }

    .image-web {
        height: 160px;
        width: 100%;
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 16px;
    }

    .container-web-blocked {
        position: relative;
    }

    .remove-bloqued {
        background-color: transparent; 
        i { color: $red; }
    }

    .data {
        position: relative;
        .remove-bloqued {
            top: 14px;
            right: 0;
            position: absolute;
        }
    }

    .top {
        position: absolute;
        top:0;
    }

    .controlls {
        background-color: $white;
        
        input { 
            width: 70%;
            color: $darkGray;
            font-size: 0.9rem;
            background-color: $white;
            padding: 14px $paddingMedium;
            &::placeholder {font-size: 0.85rem; }
        }
        .btn { width: 100%; padding: 14px $paddingNormal; width: 30%; }
    }
}

@media screen and (max-width: 992px) {
    .blocked {
        .grid-container {
            display: grid;
            grid-template-columns: repeat(2,1fr);
            grid-gap: 16px;
        }
    }
}

@media screen and (max-width: 768px) { 
    
.blocked {

    .blocked-list {
        background-color: $white;
        padding: 0 $paddingNormal;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
    }

    .remove-bloqued {
        background-color: transparent; 
        i { color: $red; }
    }

    .data {
        position: relative;
        .remove-bloqued {
            top: 14px;
            right: 0;
            position: absolute;
        }
    }

    .controlls {
        bottom: 59.5px;
        position: fixed;
        background-color: $white;
        
        input { 
            width: 70%;
            color: $darkGray;
            font-size: 0.9rem;
            background-color: $white;
            padding: 14px $paddingMedium;
            &::placeholder {font-size: 0.85rem; }
        }
        .btn { width: 100%; padding: 14px $paddingNormal; width: 30%; }
    }
}

}