/**
*   modals.scss
*   @description: Estilos de los Modales
*/


/**
*   Generales
*       modal-login
*       #modal-promotion
*       #modal-alert
*/

.modal {
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}

#modal-profileAlert .modal-content {
    background-color: $white;
    width: 40%;
}

#modal-delePublication .modal-content, #modal-deleInfluencerPublication .modal-content, #modal-delete-chat .modal-content, #modal-delete-account .modal-content {
        background-color: $white;
        border-radius: 4px;
        width: 30%;

    #btn-deletePublication {
        width: 50%;
        padding: $paddingMedium;
        border-radius: 0 0 0 4px;
        background-color: $pink;
        border: 0;
    }
    #btn-deletePublicationCancel {
        width: 50%;
        padding: $paddingMedium;
        border-radius: 0 0 4px 0;
        border: 0;
    }
}


#modal-login .modal-content, #modal-register .modal-content, #modal-contact .modal-content {
    width: 40%;
    margin: auto;
    position: relative;
    align-self: flex-start;
    border-radius: 10px;
    background-color: $white;

    .text-regis {
        margin-left: 8px;
    }

    p, form {
        width: 60%;
    }     

    #close-modal-login, #close-modal-register {
        width: auto;
        position: absolute;
        top: 15px;
        right: 16px;
        background: $white;
        color: $pink;
        &:hover {
            color : $purple;
        }
        i {
            font-size: $fontLarge;
        }
    }

    .logo-modal {
        position: absolute;
        left: 16px;
        top: 8px;
        width: 17%;
    }

    .icon-insta {
        margin-right: 8px;
    }

}

#modal-contact .modal-content form {
    width: 80%;
}

#modal-contact .modal-content p {
    width: 100%;
}

#modal-contact .modal-content  {
    width: 60%;
    margin: auto;
    position: relative;
    align-self: flex-start;
    border-radius: 10px;
    background-color: $white;

    .image-profile-modal {
        max-height: 340px;
    }   

    #close-modal-contact {
        position: absolute;
        top: 15px;
        right: 16px;
        background: $white;
        color: $pink;
        &:hover {
            color : $purple;
        }
        i {
            font-size: $fontLarge;
        }
    }


}

#modal-delate .modal-content, #modal-delete-chat .modal-content, #modal-delete-account .modal-content {
    width: 30%;
    margin: auto;
    position: relative;
    align-self: flex-start;
    background-color: $white;

    .image-profile-modal {
        max-height: 340px;
    }   

    .btn-confirm{
        margin-right: 8px;
    }
}

#modal-delete-account .modal-content {
    button {
        width: 50%;
        padding: $paddingMedium;
    }
}

#modal-delate-account .modal-content, #modal-delete-chat .modal-content {
    width: 90%;
    margin: auto;
    position: relative;
    align-self: flex-start;
    background-color: $white;

    .image-profile-modal {
        max-height: 340px;
    }   

    .btn-confirm{
        margin-right: 8px;
    }
}

#modal-activation .modal-content  {
    width: 30%;
    margin: auto;
    position: relative;
    align-self: flex-start;
    border-radius: 10px;
    background-color: $white;

}

#modal-Add .modal-content  {
    width: 40%;
    margin: auto;
    position: relative;
    align-self: flex-start;
    border-radius: 10px;
    background-color: $white;

    .image-profile-modal {
        max-height: 340px;
    }   

    .btn-confirm{
        margin-right: 8px;
    }

    #close-modal-Add {
        position: absolute;
        top: 10px;
        right: 10px;
        color: $pink;
        &:hover {
            color : $purple;
        }
        i {
            font-size: $fontLarge;
        }
    }
}

#modal-show .modal-content  { 

    .modal-image{
        max-width: 90%;
    }
  
    #close-modal-show {
        position: absolute;
        background-color: rgba(0, 0, 0, 0);
        top: 10px;
        right: 10px;
        color: $white;
        &:hover {
            color : $pink;
        }
        i {
            font-size: $fontDouble;
        }
    }
}

#modal-delate-chat .modal-content, #modal-delete-chat .modal-content {
    background-color: $white;
    width: 30%;
    margin: auto;
    position: relative;
    align-self: flex-start;

    #btn-deleteChat { 
        width: 50%; 
        padding: $paddingMedium;
        border-radius: 0 0 0 4px;
    }

    #close-modal-delete {
        width: 50%; 
        padding: $paddingMedium;
        border-radius: 0 0 4px 0;
    }
}

#modal-pay .modal-content  {
    width: 40%;
    margin: auto;
    position: relative;
    border-radius: 4px;
    align-self: flex-start;
    background-color: $white;
    button { 
        width: 50%; 
        padding: $paddingMedium;
        &:first-child { border-radius: 0 0 0 4px; }
        &:last-child { border-radius: 0 0 4px 0; }
    }
}

#modal-no-publish .modal-content {
    width: 40%;
    margin: auto;
    position: relative;
    align-self: flex-start;
    background-color: $white;
    border-radius: 4px;
    button { 
        width: 50%; 
        padding: $paddingMedium;
        &:first-child { border-radius: 0 0 0 4px; }
        &:last-child { border-radius: 0 0 4px 0; }
    }
}

#modal-paychat .modal-content {
    max-width: 40%;
    margin: auto;
    position: relative;
    align-self: flex-start;
    border-radius: 10px;
    background-color: $white;

    #form1 { width: 90%; }

    .content-options {
        position: relative;

        .icon-triangle {
            position: absolute;
            right: 8px;
        }

        .none-input { -webkit-appearance: none; }
    }

    #btn-pay-button {
        left: 0;
        bottom: 0;
        border-radius: 0 0 4px 4px;
        position: absolute;
    }

    #close-modal-paychat {
        position: absolute;
        background-color: rgba(0, 0, 0, 0);
        top: 5px;
        right: 0px;
        color: $purple;
        &:hover { color : $pink; }
        i { font-size: $fontMedium; }
    }
  
}


@media screen and (max-width: 992px) {

    #modal-login .modal-content  {
        width: 70%;    

        p, form {
            width: 70%;
        }     

        #close-modal-login {
            position: absolute;
            top: 16px;
            right: 16px;
            i {
                font-size: $fontRegular;
            }
        }

    }

    #modal-Add .modal-content  {
        width: 55%;
        margin: auto;
        position: relative;
        align-self: flex-start;
        border-radius: 10px;
        background-color: $white;
    }

    #modal-activation .modal-content  {
        width: 40%;
        margin: auto;
        position: relative;
        align-self: flex-start;
        border-radius: 10px;
        background-color: $white;
    
    }

    #modal-paychat .modal-content {
        max-width: 60%;
    }
}


@media screen and (max-width: 768px) {
    
    .modal-content {
        background-color: $white;
        border-radius: 4px;
    }

    #modal-profileAlert .modal-content {
        background-color: $white;
        width: 80%;
    }

    #modal-delePublication .modal-content, #modal-deleInfluencerPublication .modal-content, #modal-delete-chat .modal-content, #modal-pay .modal-content, #modal-no-publish .modal-content, #modal-delete-account .modal-content, #modal-contact .modal-content {
        width: 80%;

        h4 { padding: 0 $paddingNormal; }
        p { padding: 0 $paddingNormal; }

        #btn-deletePublication {
            width: 50%;
            padding: $paddingMedium;
            border-radius: 0 0 0 4px;
            background-color: $pink;
            border: 0;
        }

        #btn-deletePublicationCancel {
            width: 50%;
            padding: $paddingMedium;
            border-radius: 0 0 4px 0;
            border: 0;
        }

        #btn-deleteChat { 
            width: 50%; 
            padding: $paddingMedium;
            border-radius: 0 0 0 4px;
        }

        #close-modal-delete, #close-modal-delete-account {
            width: 50%; 
            padding: $paddingMedium;
            border-radius: 0 0 4px 0;
        }
    }

    #modal-contact .modal-content form {
        width: 90%;
    }

    #modal-contact .modal-content p {
        width: 100%;
        padding-left: 2px;
    }

    #modal-login .modal-content  {
        width: 80%;

        p, form {
            width: 70%;
        }     

        #close-modal-login {
            position: absolute;
            top: 16px;
            right: 16px;
            i {
                font-size: $fontRegular;
            }
        }
    }

    #modal-show .modal-content  { 
        background-color: transparent;

        .modal-image{
            max-width: 98%;
        }
  
        #close-modal-show {
            position: absolute;
            background-color: rgba(0, 0, 0, 0);
            top: 10px;
            right: 10px;
            color: $white;
            &:hover {
                color : $pink;
            }
            i {
                font-size: $fontDouble;
            }
        }
    }

    #modal-delate-chat .modal-content, #modal-delete-chat .modal-content {
        background-color: $white;
        width: 80%;
        margin: auto;
        position: relative;
        align-self: flex-start;
    }

    #modal-paychat .modal-content {
        max-width: 90%;
        margin: auto;
        position: relative;
        align-self: flex-start;
        border-radius: 10px;
        background-color: $white;

        #form1 { width: 90%; }

        .content-options {
            position: relative;

            .icon-triangle {
                position: absolute;
                right: 8px;
            }
    
            .none-input { -webkit-appearance: none; }
        }

        #btn-pay-button {
            left: 0;
            bottom: 0;
            border-radius: 0 0 4px 4px;
            position: absolute;
        }

        #close-modal-paychat {
            position: absolute;
            background-color: rgba(0, 0, 0, 0);
            top: 5px;
            right: 0px;
            color: $purple;
            &:hover { color : $pink; }
            i { font-size: $fontMedium; }
        }
      
    }
    
}

@media screen and (max-width: 576px) {
    #modal-login .modal-content  {
        width: 95%;
        background-position: left;
        p, form {
            width: 80%;
        }

    }
}
