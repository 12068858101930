/**
*   publications.scss
*   @description: Estilos de la página de publicaciones
*/

.publication {
    min-height: 50vh;
    .publication-grow {
        flex-grow: 1;
    }    

    .icon-info {
        margin-right: 8px;
        color: $darkGray;
    }

    .image-add {
        width: 100%;
        height: 335px;
    }
    .image-edit {
        height: 530px;
        width: 160%;
        border-radius: 4px 4px 0 0;
        img { 
            width: 100%;
            height: 530px;
            object-fit: cover;
        }
    }
    .image {
        height: 524px;
        width: 160%;
        border-radius: 4px 4px 0 0;
        img { 
            width: 100%;
            height: 524px;
            object-fit: cover;
        }
    }
    
    .details {
        padding-top: $paddingNormal;
        background-color: $white;
        flex-grow: 1;
        position: relative;
        padding: 0px 24px 0px;

    }
    .details .price, .details .shipping { width: 50%; }

    .details .price { padding-left: 4px; }
    .details .shipping { padding-right: 16px; }
    .details .icon { padding: 4px 6px; }
    .details .description { padding: 0 6px; }
    .description p { width: 95%; }
    .btn { width: 100%; min-height: 40px; }

    .input-edit {
        max-width: 100%;
        font-size: $fontMini;
        padding: $paddingNormal;
        margin: $paddingNormal 0;
        background-color: $white;
        border-bottom: 1px solid $gray;
        transition: border-bottom 400ms ease-in-out;
        &:focus { border-bottom: 1px solid $pink; }
    }
    #price-edit { max-width: 80%; }
    #form-edit { margin-bottom: 32px; }
    #form-add { margin-bottom: 32px; }
    textarea {
        padding: 6px;
        max-width: 94%;
        line-height: 22px;
        font-size: $fontMini;
        background-color: $white;
        margin-top: $paddingNormal;
        border-bottom: 1px solid $gray;
        transition: border-bottom 400ms ease-in-out;
        &:focus { border-bottom: 1px solid $pink; }
    }
    #to-bottom {
        flex-grow: 1;
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

.publication-edit, .publication-add {
    .details {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 768px) {

    .publication {
        min-height: 90vh;

        .view-web { display: none; }

        .publication-grow {
            flex-grow: 1;
        }

        .container-publication { 
            width: 92%;
            margin-top: $paddingNormal;
        }

        .image {
            height: 250px;
            width: 100%;
            border-radius: 4px 4px 0 0;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
            img { 
                width: 100%;
                height: 100%;
                border-radius: 4px 4px 0 0; 
            }
        }
        .details {
            flex-grow: 1;
            margin-bottom: 64px;
            background-color: $white;
            border-radius: 0 0 4px 4px;
            padding: 0;
            padding-top: $paddingNormal;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
        }
        .details .price, .details .shipping { width: 50%; }
        .details .price { width: 30%; }
        .details .shipping { width: 70%; }

        .details .price { 
            padding-left: $paddingNormal;
            p { margin-left: -2.5px;}
        }
        .details .shipping { padding-right: 22px; }
        .details .icon {
            i { font-size: 0.8rem; margin-top: 2px; }
        }
        .details .description { padding-left: $paddingNormal }
        .description p { width: 95%; }
        .btn { width: 100%; }

        .input-edit {
            max-width: 100%;
            font-size: $fontMini;
            padding: $paddingNormal;
            margin: $paddingNormal 0;
            background-color: $white;
            border-bottom: 1px solid $gray;
            transition: border-bottom 400ms ease-in-out;
            &:focus { border-bottom: 1px solid $pink; }
        }
        #price-edit { max-width: 100%; }
        #form-edit { margin-bottom: 32px; }
        #form-add { margin-bottom: 32px; }
        textarea {
            padding: 6px;
            max-width: 94%;
            line-height: 22px;
            font-size: $fontMini;
            background-color: $white;
            margin-top: $paddingNormal;
            border-bottom: 1px solid $gray;
            transition: border-bottom 400ms ease-in-out;
            &:focus { border-bottom: 1px solid $pink; }
        }

        .btn-add {
            right: 0;
            width: 100%;
            bottom: 59px;
            position: fixed;
        }
        
        #to-bottom {
            left: 0;
            bottom: 59px;
            flex-grow: 1;
            position: fixed;
        }
        .titles, .title { padding: 0 8px; }
        .btn-chat {  padding: 12px $paddingMedium; }
    }

    .publication-edit, .publication-add {
        .details { margin-bottom: 24px; }
        .icon i { font-size: 0.8rem; }
        .price .info p { margin-left: 5px; }
        #select {
            width: 100%;
            margin-left: 4px;
            margin-top: 6px;
        }
        .description-detail { padding: 0 9px; }
        #btn-save, #btn-save .btn { border-radius: 0 0 4px 4px; }
    }
}