/**
*   interest.scss
*   @description: Estilos de la página de intereses
*/

.interests {
    background: white;

    #back-interest {
        padding-top: 5px;
    }

    #btn-update {
        background-color: transparent;
        &:hover { background-color: $white; }
    }

    .interests-options {

        .container-grid{
            display: grid;
            grid-gap: 24px;
            grid-template-columns: repeat(6,1fr);
           
        }
        .photo {
            width: 100%;  
            height: 180px;
            position: relative;
            cursor: pointer;
        }

        .img-gallery {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
            
        }
        
        .tittle {
            position: absolute;
            height: 100%;
            background: #13131370;
            border-radius: 10px;

            
            &:hover {
                background: #7633fda2;;
            }

        
        }

        .active-option {
            background: #7633fdab;
            &:before {
                content: "\f058";
                font-family:"Font Awesome 5 Free";
                font-style: normal;
                font-weight: normal;
                text-decoration: inherit; 
                color: $white;
                border: 2px solid $purple;
                background-color: $purple;
                border-radius: 50%;
                font-size: $fontLarge;
                font-weight: $weightBold;
                position: absolute;
                top: 5px;
                right: 5px;
                }
        }
    }
}

@media screen and (max-width: 992px) {
    .interests {
        .interests-options {
            .container-grid{
                display: grid;
                grid-gap: 24px;
                grid-template-columns: repeat(4,1fr);
            
            }
            .photo {
                width: 100%;  
                height: 160px;
                position: relative;
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width: 768px) {
 
    .interests {

        height: 100vh;

        .interests-container {
            max-height: 460px;
            overflow-y: scroll;
        }

        .view-web { display: none; }


        .interests-element {
            width: 30%;
            margin: $marginNormal 0;
            .circle {
                width: 100px;
                height: 100px;
                cursor: pointer;
                border-radius: 50%;
                border: 1px solid $pink;
                background-color: $white;
                transition: all 400ms ease-in-out;
                -webkit-tap-highlight-color: transparent;
                &.active {
                    color: $white;
                    background-color: $pink;
                }
            }
            p { height: 46px; }
        }
        
        #btn-top {
            left: 0;
            bottom: 60px;
            position: fixed;
            font-family: $OpenSans;
            .btn { width: 100%; }
        }  
        
        #btn-top-intereses {
            left: 0;
            bottom: 0;
            width: 100%;
            position: fixed;
            font-family: $OpenSans;
            .btn { 
                width: 100%; padding: $paddingNormal $paddingMedium; 
            }
        }

    }

}

@media screen and (max-width: 576px) {

    .interests {
        .interests-element {
            width: 33%;
            .circle {
                max-width: 80px;
                max-height: 80px;
                border-radius: 50%;
            }
        }
    }

}
