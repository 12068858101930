/**
*   profile-public.scss
*   @description: Estilos para la vista publica de perfil
*/

.profile-influencer, .profile-brand {
    background-color: $lightGray;

    .loading-center {
        top: calc(50% - 2.5rem);
        left: 0;
        position: absolute;
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 16px;
    }

    .container-profile {
        width: 70%;
    }

    .container-categories {
        padding: 8px 16px;
        background: $white;

        .category{
            background: $purple;
            padding: $paddingNormal $paddingMedium;
            border-radius: 5px;
            margin-right: 8px;
        }
    }

    .header-profile {
        width: 100%;
        height: auto;
        background: white;        

        .icon-config {
            color: gray;
            cursor: pointer;
            font-size: $fontLarge;
            padding-right: 46px;
            transition: color 300ms ease-in-out;
            &:hover { color: $purple; }
        }

        .public-profile-column { width: 60%; }

        .data-head {
            position: relative;
            padding: $paddingMedium 0;            
        }

        .data-head-top {
            top: 28px;
            position: absolute;
        }

        .data-head-bottom {
            position: absolute;
            bottom: 32px;
        }

        .content-btns {
            padding: $paddingMedium;
            width: 50%;
        }        

        .column-located {
            width: 20%;
        }

        .column-followers {
            width: 35%
        }

        .column-price {
            width: 50%
        }

        .setting-icon {
            i { padding-top: 8px; }
        }

       
    }

    .gallery-profile {
        .photo {
            width: 100%;
            height: 280px;
            position: relative;
            transition: all .5s;
          
            
            #delete {
                z-index: 1;              
                background: transparent;
                color: $pink;
                font-size: $fontLarge;
                font-weight: $weightBold;
                position: absolute;
                top: 15px;
                cursor: pointer;
                right: 15px;
                
                &:hover{
                    color: $purple;
                }

            }

        }

        .img-gallery {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
            
        }

        .message-center {
            i { font-size: 2.5rem; }
            p { width: 60%; }
        }
    }

    .profile-column {
        width: 65%;
        .profile-image {
            width: 140px;
            height: 140px;
            border-radius: 50%;
            margin: 16px 40px 16px 0px;
            border: 2px solid #FFFFFF;
            object-fit: cover;
            object-position: center center;
        }
    }

    .profile-public {
        width: 50%;
        .profile-image {           
            margin: 16px 20px 16px 0px;           
        }
    }

    .cards-container {
        min-height: 200px;
        @include brandcards;
    }

    .skills {
        overflow-x: scroll;
        .interes {
            width: auto;
            padding: 0 4px 6px 0;
            align-self: flex-start;
            &:first-child { padding-left: 0; }
            &:last-child { padding-right: 0; }
            p {
                width: 100%;
                border-radius: 4px;
                background-color: $pink;
                padding: $paddingNormal;
            }
        }
    }           
    
    .message-center {
        i { font-size: 2.5rem; }
        p { width: 60%; }
    }

}

    .cards-container-mine {
        h4 { font-size: 1rem; }
        .message-center {
            i { font-size: 2.5rem; }
            p { width: 60%; }
        }
}

@media screen and (max-width: 992px){
    .profile-influencer, .profile-brand {
        background-color: $lightGray;
        .container-profile {
            width: 85%;
        }
        .container-categories{
            padding: 8px 16px;
            background: $white;
    
            .category{
                background: $purple;
                padding: $paddingNormal $paddingMedium;
                border-radius: 5px;
                margin-right: 8px;
            }
        }
    
        .header-profile {
            height: auto;
            background: white;
    
            .icon-config {
                cursor: pointer;
                font-size: $fontLarge;
                color: gray;
    
                &:hover{
                    color: $purple;
                }
            }
    
            .public-profile-column{
                width: 60%;
            }
    
            .content-btns{
                padding: $paddingMedium;
                width: 50%;
            }            
    
            .column-located{
                width: 20%;
            }
    
            .column-followers{
                width: 35%
            }
    
            .column-price{
                width: 50%
            }
    
           
        }

        .grid-container {
            grid-gap: 2px;
        }
    
        .gallery-profile{
            .photo {
                width: 100%;
                height: 220px;
                position: relative;
                padding: 8px;
                transition: all .5s;
              
                .delate {
                    color: $pink;
                    font-size: $fontLarge;
                    font-weight: $weightBold;
                    position: absolute;
                    top: 15px;
                    cursor: pointer;
                    right: 15px;
                    
                    &:hover{
                        color: $purple;
                    }
    
                }
    
            }
    
            .img-gallery {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
                
            }
        }
    
        .profile-column{
            width: 65%;
            .profile-image {
                width: 140px;
                height: 140px;
                border-radius: 50%;
                margin: 16px 40px 16px 0px;
                border: 2px solid #FFFFFF;
                object-fit: cover;
                object-position: center center;
            }
        }
    
        .profile-public{
            width: 70%;
            .profile-image {           
                margin: 16px 20px 16px 0px;           
            }
        }

        .cards-container {
            min-height: 200px;
            
            @include brandcards;
            .empty {
                height: 200px;
            }
        }
    }
}

@media screen and (max-width: 768px) {

    .profile-influencer, .profile-brand {

        .movile-labels {
            height: 65px;
            background-color: #FF0357;
            border-radius: 4px;

            p {
                font-size: .8rem;
                line-height: 17px;
            }
        }

        .view-web { display: none; }

        .text-space { margin-right: 10%; }

        .influencer-cards {
            margin: 0;
            min-height: 300px;
            border-radius: 4px 4px 0 0;
            box-shadow: 0 1px 2px 0 rgba(0,0,0,0.10);
            .image {
                max-height: 330px;
                min-height: 330px;            
                position: relative;
                border-radius: 0;
                background-color: $black;
                img { 
                    opacity: 0.6; 
                    object-position: center;
                }
                .loader-img {
                    width: 100%;
                    background: white;
                }
            }
            
            .icon-favorites {
                position: absolute;
                top: -22.25px;
                right: $paddingNormal;
                z-index: 1;
            }
            
            .title {
                top: 0;
                height: 100%;
                position: absolute;
            }
            .bottom {
                bottom: $paddingMedium;
                position: absolute;
            }
            .tabs-container .btn { 
                width: 50%;
                border-left: 1px solid #E7E7E7;
                border-bottom: 1px solid #E7E7E7;
                border-right: 1px solid #E7E7E7;
                background-color: $white;
            }
        }

        .brand-cards {
            box-shadow: 0 1px 2px 0 rgba(0,0,0,0.10);
            .data { margin-bottom: 4px; 
                .icon-favorites {
                    position: absolute;
                    top: 11.5px;
                    right: $paddingNormal;
                }
            }
        }

        .gallery {
            .photo {
                width: 50%;
                padding: 2px;
                cursor: pointer;
                img {
                    width: 100%;
                    height: 200px;
                }
            }
            .empty {
                height: 200px;
            }

            .owner-photo {
                position: relative;
                #delete-photo {
                    z-index: 1;
                    position: absolute;
                    top: $paddingNormal;
                    right: $paddingNormal;
                    background: transparent;
                }
            }
        }        

        .fixed-chat {
            position: fixed;
            bottom: 59px;
            .btn {
                width: 100%;
                height: 40px;
                font-family: $OpenSans;
            }
        }

        .banner-brand {
            background-color: $white;
        }

        .tabs-container-brand .btn { 
            width: 50%;
            background-color: $white;
            border: 1px solid #E7E7E7;
        }

        .brand-picture {
            margin: $marginMedium 0;
            img { 
                max-width: 80px;
                height: 80px; 
                border-radius: 50%;
            }
        }

        .cards-container {
            min-height: 200px;
            @include brandcards;
            .empty {
                height: 200px;
            }
        }

        .intereses-container {
            padding: 4px 0;
            min-height: 200px;
            align-content: flex-start;
            .interes {
                width: 33%;
                padding: 4px;
                align-self: flex-start;
                p {
                    width: 100%;
                    border-radius: 4px;
                    background-color: $pink;
                    padding: $paddingNormal 0;
                }
            }
        }

        .skills {
            .interes {
                width: auto;
                padding: 4px;
                &:first-child { padding-left: 0; }
                &:last-child { padding-right: 0; }
                p {
                    width: 100%;
                    border-radius: 4px;
                    background-color: $pink;
                    padding: $paddingNormal;
                }
            }
            .flickity-viewport { width: 0; }
            .flickity-button:disabled { display: none; }
        } 

    }
}