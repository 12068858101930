/**
*   buttons.scss
*   @description: Estilos para botones
*/

.btn {
    cursor: pointer;
    border-radius: 50px;
    font-family: $OpenSans;
    padding: $paddingNormal $paddingMedium;
    -webkit-tap-highlight-color: transparent;
}

.btn-web{
    cursor: pointer;
    border-radius: 5px;
    padding: 8px 16px;
    -webkit-tap-highlight-color: transparent;
}

.btn-web-forms {
    cursor: pointer;
    border-radius: 5px;
    padding: $paddingMedium $paddingLarge;
    -webkit-tap-highlight-color: transparent;
}

.btn-square {
    border-radius: 0;
}

.btn-border-white {
    border: 1px solid $white;
    -webkit-transition: background-color  .5s;
    transition: background-color  .5s;

    &:hover{
        background-color: $white;
        color: $purple;
        font-weight: $weightBold;
    }
}

.btn-public, .btn-public-fav {
    width: 100%;
    height: 80px;
    border-radius: 5px;
    padding: 20px 16px;
}

.btn-modal-login {
    width: 100%;
    border-radius: 16px;
    padding: $paddingMedium $paddingLarge;
    transition: background-color 400ms ease-in-out;
    &:hover {
        color: $white;
        background-color: $purple;      
    }
}

.btn-modal-pay {
    width: 100%;
    border-radius: 16px;
    padding: 12px $paddingMedium;
}

.btn-interests {
    width: 100%;
    padding: 12px $paddingMedium;
}

.btn-border-pink {
    width: 100%;
    border-radius: 16px;
    border: 1px solid $pink;
    background-color: $white;
    padding: $paddingMedium $paddingLarge;
    transition: background-color 400ms ease-in-out;
    &:hover {
        color: $white;
        background-color: $purple;
        border: 1px solid $purple;
    }
}

.btn-modal-activation {
    width: 100%;
    cursor: pointer;
    border-radius: 5px;
    padding: 16px 32px;
}

.btn-active {
    height: 60px;
}

.btn-inactive {
    height: 60px;
}

.btn-border-pink-splash {
    width: 100%;
    border: 5px solid $pink;
    background-color: $white;
    padding: $paddingMedium $paddingLarge;
    transition: background-color 400ms ease-in-out;
    &:hover {
        color: $white;
        background-color: $pink;
        border: 5px solid $pink;
    }
}

.btn-border-purple-splash {
    width: 100%;
    border: 5px solid $purple;
    background-color: $white;
    padding: $paddingMedium $paddingLarge;
    transition: background-color 400ms ease-in-out;
    &:hover {
        color: $white;
        background-color: $purple;
        border: 5px solid $purple;
    }
}

.btn-border-gray-splash {
    width: 100%;
    border: 2px solid $gray;
    background-color: $white;
    padding: $paddingMedium $paddingLarge;
    transition: background-color 400ms ease-in-out;
    &:hover {
        color: $white;
        background-color: $gray;
        border: 2px solid $gray;
    }
}

.btn-payments {
    width: 35%;
}

@media screen and (max-width: 768px) {
    .btn-payments {
        width: 100%;
    }
}

.btn-pink { background-color: $pink; }

.btn-purple { background-color: $purple; }

.btn-red { background-color: $red; }

@media screen and (min-width: 1440px) {
    .btn-active {
        height: 75px;
    }
    
    .btn-inactive {
        height: 75px;
    }

    
.btn-border-pink-splash {
    width: 100%;
    border: 5px solid $pink;
    background-color: $white;
    padding: $paddingMedium 68px;
    transition: background-color 400ms ease-in-out;
    &:hover {
        color: $white;
        background-color: $pink;
        border: 5px solid $pink;
    }
}

.btn-border-purple-splash {
    width: 100%;
    border: 5px solid $purple;
    background-color: $white;
    padding: $paddingMedium 68px;
    transition: background-color 400ms ease-in-out;
    &:hover {
        color: $white;
        background-color: $purple;
        border: 5px solid $purple;
    }
}

.btn-border-gray-splash {
    width: 100%;
    border: 2px solid $gray;
    background-color: $white;
    padding: $paddingMedium 68px;
    transition: background-color 400ms ease-in-out;
    &:hover {
        color: $white;
        background-color: $gray;
        border: 2px solid $gray;
    }
}
}