
::-webkit-scrollbar { width: 8px; }

::-webkit-scrollbar-thumb { background-color: $pink; }

@media screen and (max-width: 768px) {

    ::-webkit-scrollbar { width: 0; }
    
    ::-webkit-scrollbar-thumb { background-color: transparent; }
}